import { defineComponent as n, openBlock as a, createBlock as o, resolveDynamicComponent as s, normalizeClass as r, withCtx as i, createElementVNode as l, renderSlot as u } from "vue";
import '../css/Button.css';const c = { class: "button-inner" }, p = /* @__PURE__ */ n({
  __name: "Button",
  props: {
    /**
     * A dynamic component or an HTML tag to render.
     * This is useful when rendering `<RouterLink>` or `<NuxtLink>` components.
     *
     * @default 'button'
     */
    is: {
      type: [Object, String],
      default: "button"
    },
    /**
     * The color variant of the button.
     *
     * @default 'primary'
     */
    variant: {
      type: String,
      default: "primary"
    },
    /**
     * The size of the button.
     *
     * @default 'medium'
     */
    size: {
      type: String,
      default: "medium"
    }
  },
  setup(t) {
    return (e, m) => (a(), o(s(t.is), {
      class: r(["button", [`button-${t.size}`, `button-${t.variant}`]])
    }, {
      default: i(() => [
        l("span", c, [
          u(e.$slots, "default")
        ])
      ]),
      _: 3
    }, 8, ["class"]));
  }
});
export {
  p as _
};
